import discountGroups from '../static/discountGroups';
import { userDataComputed } from '../store/helper';
export const productUnitPrice = {
  computed: {
    ...userDataComputed,
  },
  methods: {
    productUnitPrice(product) {
      const price = this.userDiscountGroup(product)
        ? this.getGroupPriceByQuantity(product)
        : product.standardPrice;
      return price;
    },
    userDiscountGroup(product) {
      const discountGroup = this.userData.discountGroup ?? discountGroups.BASIC;
      return product.discountPrices?.find((disc) => disc.name === discountGroup);
    },
    getGroupPriceByQuantity(product) {
      let price;

      if (product.quantity >= 10 && product.quantity < 30) {
        price = this.userDiscountGroup(product)['price']['palette'];
      }

      if (product.quantity >= 30) {
        price = this.userDiscountGroup(product)['price']['container'];
      }

      return price ? price : this.userDiscountGroup(product)['price']['normal'];
    },
  },
};
