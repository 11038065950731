import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/offers`;

axios.defaults.withCredentials = true;

const options = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf',
  },
};

class OfferService {
  static async getOffer(id) {
    const res = await axios.get(`${url}/${id}`);

    return res.data;
  }
  static async getAllUserOffers(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/user`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllCompanyOffers(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/company`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async create(offer) {
    const res = await axios.post(url, offer);
    return res.data;
  }

  static async delete(id) {
    const res = await axios.delete(`${url}/${id}`);
    return res.data;
  }

  static async update(id, offer) {
    const res = await axios.put(`${url}/${id}`, offer);
    return res.data;
  }

  static async generateBlob(res) {
    return new Blob([res], {
      type: 'application/pdf',
    });
  }

  static async generateOfferPdf(id) {
    const res = await axios.get(`${url}/${id}/generatePdf`, options);
    const blob = this.generateBlob(res.data);
    return blob;
  }

  static async generateListPdf(id) {
    const res = await axios.get(`${url}/${id}/generatePdf/productsList`, options);
    const blob = this.generateBlob(res.data);
    return blob;
  }
}

export default OfferService;
